import { AddEthereumChainParameter } from "@web3-react/types";

const AVALANCHE_MAINNET_PARAMS: AddEthereumChainParameter = {
  chainId: 0xa86a,
  chainName: "Avalanche Mainnet C-Chain",
  nativeCurrency: {
    name: "Avalanche",
    symbol: "AVAX",
    decimals: 18,
  },
  rpcUrls: [
    "https://api.avax.network/ext/bc/C/rpc",
    "https://speedy-nodes-nyc.moralis.io/f1b5142c431c433694cb8d5b/avalanche/mainnet",
  ],
  blockExplorerUrls: ["https://snowtrace.io/"],
};

const AVALANCHE_TESTNET_PARAMS: AddEthereumChainParameter = {
  chainId: 0xa869,
  chainName: "Avalanche Testnet C-Chain",
  nativeCurrency: {
    name: "Avalanche",
    symbol: "AVAX",
    decimals: 18,
  },
  rpcUrls: [
    "https://speedy-nodes-nyc.moralis.io/f1b5142c431c433694cb8d5b/avalanche/testnet",
    "https://api.avax-test.network/ext/bc/C/rpc",
  ],
  blockExplorerUrls: ["https://testnet.snowtrace.io/"],
};

export const NETWORK: AddEthereumChainParameter =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development" ? AVALANCHE_TESTNET_PARAMS : AVALANCHE_MAINNET_PARAMS;
