import { Web3Provider } from "@ethersproject/providers";
import { ethers } from "ethers";
import React, { useState } from "react";
import { MintType } from "../constants/mint-type";
import Spinner from "../constants/spinner-svg";
import { AsgardianBulls } from "../generated/contract-types";

type MintDetails = {
  contractReceipt: ethers.ContractReceipt;
  marketLocations: string[];
};

const MintCard = (props: {
  title: string;
  mintPrice: number;
  mintType: MintType;
  contract: AsgardianBulls;
  provider: Web3Provider;
  providerError: Error | undefined;
  setShouldUpdate: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [mintAmount, setMintAmount] = useState(1);
  const [price, setPrice] = useState(Number(props.mintPrice).toFixed(1));
  const [showModal, setShowModal] = useState(false);
  const [mintDetails, setMintDetails] = useState<MintDetails>();
  const [mintErrorDetails, setMintErrorDetails] = useState<string>();

  async function mint(
    mintType: MintType,
    contract: AsgardianBulls,
    provider: Web3Provider,
    mintAmount: number,
    price: string
  ) {
    let tx: Promise<ethers.ContractTransaction>;

    try {
      switch (mintType) {
        case MintType.FREE:
          tx = mintFree(contract, provider, mintAmount);
          break;
        case MintType.WHITELIST:
          tx = mintWhitelist(contract, provider, mintAmount, price);
          break;
        case MintType.PUBLIC:
          tx = mintPublic(contract, provider, mintAmount, price);
          break;
        default:
          throw Error("Unable to determine mint type");
      }

      const signerAddress = await provider.getSigner().getAddress();
      const receipt = await (await tx).wait();
      const tokenIds = await contract.walletOfOwner(signerAddress);
      let nftradeTokenLocations: string[] = [];
      if (tokenIds && tokenIds.length > 0) {
        nftradeTokenLocations = tokenIds.map((token) =>
          `https://nftrade.com/assets/avalanche/${contract.address}/${token.toNumber()}`.trim()
        );
      }
      const mintDetails: MintDetails = {
        contractReceipt: receipt,
        marketLocations: nftradeTokenLocations,
      };
      setMintDetails(mintDetails);
      props.setShouldUpdate(true);
    } catch (e: any) {
      console.log(e);
      if (e && e.data && e.data.message) {
        setMintErrorDetails(e.data.message);
      } else if (props.providerError && props.providerError.message) {
        setMintErrorDetails(`(${props.providerError.name || ""}) ${props.providerError.message}`);
      } else if (e.code && e.message) {
        setMintErrorDetails(`(${e.code || ""}) :${e.message}`);
      } else {
        setMintErrorDetails("An error was encountered. Please check your account's transactions for more information");
      }
    }
  }

  return (
    <div className="text-center p-6 rounded-lg border border-gray-200 shadow-md bg-gray-800 border-gray-700">
      <h5 className="mb-2 text-2xl font-bold tracking-tight text-white">{props.title}</h5>
      <p className="mb-3 font-normal text-gray-400">Price per mint: {props.mintPrice} AVAX</p>
      <p className="mb-3 font-normal text-gray-400">Total Price: {price} AVAX</p>
      <div className="grid grid-cols-3">
        <button
          onClick={() => {
            if (mintAmount !== 1) {
              const newMintAmount = mintAmount - 1;
              setMintAmount(newMintAmount);
              setPrice(Number(newMintAmount * props.mintPrice).toFixed(1));
            }
          }}
          type="button"
          className="text-white bg-yellow-600 hover:bg-yellow-500 focus:outline-none focus:ring-4 font-medium rounded-full text-xl px-5 py-2.5 text-center mr-2 mb-2 focus:ring-yellow-900"
        >
          -
        </button>
        <p className="text-2xl text-white">{mintAmount}</p>
        <button
          onClick={() => {
            if (mintAmount !== 10) {
              const newMintAmount = mintAmount + 1;
              setMintAmount(newMintAmount);
              setPrice(Number(newMintAmount * props.mintPrice).toFixed(1));
            }
          }}
          type="button"
          className="text-white text-xl focus:outline-none focus:ring-4 font-medium rounded-full px-5 py-2.5 text-center mr-2 mb-2 bg-green-600 hover:bg-green-700 focus:ring-green-800"
        >
          +
        </button>
      </div>
      <button
        onClick={() => {
          mint(props.mintType, props.contract, props.provider, mintAmount, price);
          setShowModal(true);
        }}
        className="inline-flex items-center py-2 px-10 text-sm font-medium text-center text-white rounded-lg focus:ring-4 focus:outline-none focus:ring-blue-300 bg-blue-600 hover:bg-blue-700 focus:ring-blue-800"
      >
        Mint
      </button>
      {showModal ? (
        <>
          <div className="z-[1005] justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none">
            <div className="relative w-3/4 md:w-1/2 my-auto mx-auto">
              <div className="bg-gray-700 border-2 rounded-lg text-white shadow-lg relative flex flex-col w-full outline-none focus:outline-none">
                <div className="items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-2xl text-md:text-3xl font-semibold">Transaction Details</h3>
                </div>

                <div className="relative flex-auto">
                  <div className="text-blueGray-500 text-lg leading-relaxed">
                    {mintErrorDetails !== undefined && mintErrorDetails.length > 0 ? (
                      <p>{mintErrorDetails}</p>
                    ) : mintDetails === undefined ? (
                      <div>
                        <p>Waiting on Transaction results...</p>
                        <Spinner />
                      </div>
                    ) : mintErrorDetails === undefined || mintErrorDetails.length === 0 ? (
                      <div className="text-base text-center">
                        <a href={`https://snowtrace.io/tx/${mintDetails.contractReceipt.transactionHash}`}>
                          <p className="pt-5 text-center">View Transaction on Snowtrace: </p>
                          <p className="underline pb-5">
                            {mintDetails.contractReceipt.transactionHash.toString().substring(0, 10)}...
                          </p>
                        </a>
                        <p className="text-center text-sm">Block Hash</p>
                        <p className="text-sm">
                          {" "}
                          {mintDetails.contractReceipt.blockHash.toString().substring(0, 10)}...
                        </p>
                        <p className="pt-5 text-sm">Block Number</p>
                        <p className="text-center text-sm"> {mintDetails.contractReceipt.blockNumber}</p>
                        <p className="pt-5">View your Asgardian Bulls on NFTrade!</p>
                        <p className="text-[10px] leading-3 sm:text-xs pb-5 px-2">
                          (It may take NFTrade some time to retrieve the metadata and contract details)
                        </p>
                        <div className="flex justify-center overflow-y-scroll max-h-32">
                          <ul className="text-left list-disc text-xs md:text-sm list-inside">
                            {mintDetails.marketLocations.map((location) => (
                              <li className="leading-6 underline">
                                <a href={location} target="_blank">
                                  Asgardian Bull #{location.substring(location.lastIndexOf("/") + 1, location.length)}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    ) : (
                      <p>A problem was encountered. Please refresh the page</p>
                    )}
                  </div>
                </div>

                <div className="flex items-center justify-center border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => {
                      setShowModal(false);
                      setMintDetails(undefined);
                      setMintErrorDetails(undefined);
                      setMintAmount(1);
                      setPrice(Number(props.mintPrice).toFixed(1));
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </div>
  );
};

export default MintCard;

async function mintFree(
  contract: AsgardianBulls,
  provider: Web3Provider,
  numMints: number
): Promise<ethers.ContractTransaction> {
  const signerAddress = await provider.getSigner().getAddress();
  return contract.mintFreeList(numMints, {
    from: signerAddress,
  });
}

async function mintWhitelist(
  contract: AsgardianBulls,
  provider: Web3Provider,
  numMints: number,
  totalMintPrice: string
): Promise<ethers.ContractTransaction> {
  const signerAddress = await provider.getSigner().getAddress();
  return contract.mintWhitelist(numMints, {
    from: signerAddress,
    value: ethers.utils.parseEther(totalMintPrice),
  });
}

async function mintPublic(
  contract: AsgardianBulls,
  provider: Web3Provider,
  numMints: number,
  totalMintPrice: string
): Promise<ethers.ContractTransaction> {
  const signerAddress = await provider.getSigner().getAddress();
  return contract.mintPublic(numMints, {
    from: signerAddress,
    value: ethers.utils.parseEther(totalMintPrice),
  });
}
