import React, { useEffect, useState } from "react";
import PageLayout from "../components/page-layout";
import Spinner from "../constants/spinner-svg";
import { NETWORK } from "../constants/network";
import { hooks, metaMask } from "../connectors/metaMask";
import { MetaMaskSvg } from "../constants/metamask-svg";
import { AsgardianBulls } from "../generated/contract-types";
import { ethers } from "ethers";
import { SaleStatus } from "../constants/sale-status";
import { CONTRACT_JSON } from "../constants/contract-json";
import { Tab, Tabs } from "../elements/tabs";
import MintCard from "../components/mint-card";
import { MintType } from "../constants/mint-type";
import Blockies from "react-blockies";

const { useIsActive, useProvider, useIsActivating, useError } = hooks;

const Mint = () => {
  const error = useError();
  const isActivating = useIsActivating();
  const isActive = useIsActive();
  const provider = useProvider();

  const [isConnecting, setConnecting] = useState(false);
  const [currentSaleStatus, setCurrentSaleStatus] = useState<string | undefined>(undefined);
  const [currentSupply, setCurrentSupply] = useState<number>();
  const [connectedContract, setConnectedContract] = useState<AsgardianBulls>();
  const [rpcErrorMessage, setRpcErrorMessage] = useState("");
  const [whiteListRemaining, setWhiteListRemaining] = useState<number | undefined>(undefined);
  const [freeMintRemaining, setFreeMintRemaining] = useState<number | undefined>(undefined);
  const [signerAddress, setSignerAddress] = useState<string | undefined>(undefined);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [hideAlert, setHideAlert] = useState(false);

  const handleClick = () => setConnecting(true);

  const walletIconClasses =
    "flex items-center w-full p-3 text-base font-bold rounded-lg group hover:shadow bg-gray-600 hover:bg-gray-500 text-white";

  useEffect(() => {
    const walletConnect = async () => {
      await metaMask.activate(NETWORK);

      let contract = undefined;
      let _signerAddress = undefined;
      if (provider) {
        contract = new ethers.Contract(
          CONTRACT_JSON.address,
          CONTRACT_JSON.abi,
          provider.getSigner()
        ) as AsgardianBulls;

        _signerAddress = await provider.getSigner().getAddress();
      }

      if (_signerAddress && contract) {
        const saleStatus: SaleStatus = await contract.saleStatus();
        switch (saleStatus) {
          case SaleStatus.INACTIVE:
            setCurrentSaleStatus("Inactive");
            break;
          case SaleStatus.WHITELIST:
            setCurrentSaleStatus("Whitelist");
            break;
          case SaleStatus.PUBLIC:
            setCurrentSaleStatus("Public");
            break;
        }

        setWhiteListRemaining((await contract.whitelistMintRemaining(_signerAddress)).toNumber());
        setFreeMintRemaining((await contract.freeMintRemaining(_signerAddress)).toNumber());
        setSignerAddress(_signerAddress);
        setCurrentSupply((await contract.totalSupply()).toNumber() + 20);
        setConnectedContract(contract);
      }
      setConnecting(false);
    };

    if (isConnecting) {
      walletConnect();
    }
  }, [isConnecting, provider, signerAddress]);

  useEffect(() => {
    const refreshData = async () => {
      if (signerAddress && connectedContract) {
        const saleStatus: SaleStatus = await connectedContract.saleStatus();
        switch (saleStatus) {
          case SaleStatus.INACTIVE:
            setCurrentSaleStatus("Inactive");
            break;
          case SaleStatus.WHITELIST:
            setCurrentSaleStatus("Whitelist");
            break;
          case SaleStatus.PUBLIC:
            setCurrentSaleStatus("Public");
            break;
        }

        setWhiteListRemaining((await connectedContract.whitelistMintRemaining(signerAddress)).toNumber());
        setFreeMintRemaining((await connectedContract.freeMintRemaining(signerAddress)).toNumber());
        setSignerAddress(signerAddress);
        setCurrentSupply((await connectedContract.totalSupply()).toNumber() + 20);
        setConnectedContract(connectedContract);
      }
      setShouldUpdate(false);
    };
    if (shouldUpdate) {
      refreshData();
    }
  }, [shouldUpdate]);

  return (
    <PageLayout>
      <div className="bg-gradient-to-r from-zinc-500 via-sky-900 to-zinc-500 pt-40">
        <div
          id="mint"
          className={`container rounded-lg mx-auto flex flex-col items-center px-4 py-4 ${
            currentSaleStatus === undefined ? "h-screen" : "sm:h-screen"
          }`}
        >
          <div
            onClick={() => setHideAlert(true)}
            id="alert-4"
            className={`flex p-4 mb-4 bg-yellow-100 rounded-lg dark:bg-yellow-200 ${hideAlert ? "hidden" : ""}`}
            role="alert"
          >
            <svg
              className="flex-shrink-0 w-5 h-5 text-yellow-700 dark:text-yellow-800"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clipRule="evenodd"
              ></path>
            </svg>
            <div className="ml-3 text-sm font-medium text-yellow-700 dark:text-yellow-800">
              The mint will be closing on April 15, 2022 and the first giveaway to holders will occur on April 22, 2022
              <br />
              Don't miss your chance to mint a legendary bull, which is guaranteed to win one node a week!
            </div>
            <button
              type="button"
              className="ml-auto -mx-1.5 -my-1.5 bg-yellow-100 text-yellow-500 rounded-lg focus:ring-2 focus:ring-yellow-400 p-1.5 hover:bg-yellow-200 inline-flex h-8 w-8 dark:bg-yellow-200 dark:text-yellow-600 dark:hover:bg-yellow-300"
              data-dismiss-target="#alert-4"
              aria-label="Close"
            >
              <span className="sr-only">Close</span>
              <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
          {isActivating ? (
            <div className="w-full p-4 max-w-sm rounded-lg border shadow-md sm:p-6 bg-gray-800 border-gray-700 text-center">
              <h5 className="mb-3 text-base font-semibold lg:text-xl text-white">Unable to find MetaMask Provider</h5>
              <p className="text-sm font-normal text-gray-400">
                This page requires the MetaMask browser wallet to function properly
              </p>
              <Spinner />
            </div>
          ) : !isActive ? (
            <div className="w-full p-4 max-w-sm rounded-lg border shadow-md sm:p-6 bg-gray-800 border-gray-700 text-center">
              <h5 className="mb-3 text-base font-semibold lg:text-xl text-white">Connect with MetaMask</h5>
              <p className="text-sm font-normal text-gray-400">Connect with MetaMask to populate minting details</p>
              <div>
                <ul className="my-4 space-y-3">
                  <li>
                    <button
                      disabled={isConnecting}
                      onClick={!isConnecting ? handleClick : undefined}
                      className={walletIconClasses}
                    >
                      {MetaMaskSvg}
                      <span className="flex-1 ml-3 whitespace-nowrap">MetaMask</span>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          ) : (
            <div>
              {freeMintRemaining === undefined ||
              whiteListRemaining === undefined ||
              signerAddress === undefined ||
              provider === undefined ||
              connectedContract === undefined ? (
                <div className="text-center text-white text-xl rounded p-10 bg-gray-800">
                  <p>Retrieving contract details</p>
                  <Spinner />
                </div>
              ) : (
                <div className="grid grid-rows-3 md:grid-cols-3 md:gap-x-8 md:grid-rows-1">
                  <div className="p-5 text-center text-white bg-gray-800 rounded-lg my-auto">
                    <h2 className="text-2xl pb-3">Connected Wallet Details</h2>
                    <div className="pb-5 ">
                      <a href={`https://snowtrace.io/address/${signerAddress}`} target="_blank">
                        <Blockies
                          className="inline-flex me-5 rounded-md"
                          seed={signerAddress.toLowerCase()}
                          size={10}
                          scale={3}
                        />
                        <span className="px-2 md:text-xl underline">{signerAddress.substring(0, 10)}</span>
                      </a>
                    </div>
                    <p className="md:text-xl">Free Mints Remaining: {freeMintRemaining}</p>
                    <p className="md:text-xl pb-5">Whitelist Mints Remaining: {whiteListRemaining}</p>
                    <button
                      onClick={() => {
                        metaMask.deactivate();
                        resetState(
                          setCurrentSaleStatus,
                          setRpcErrorMessage,
                          setFreeMintRemaining,
                          setWhiteListRemaining,
                          setSignerAddress,
                          setCurrentSupply,
                          setConnectedContract
                        );
                      }}
                      type="button"
                      className="focus:outline-none text-white focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 bg-red-600 hover:bg-red-700 focus:ring-red-900"
                    >
                      Disconnet Wallet
                    </button>
                  </div>
                  <div className="text-center my-auto p-5 bg-gray-800 text-white rounded-lg">
                    <p className="text-xl md:text-2xl pb-5">Sale Status: {currentSaleStatus} </p>
                    <p className="text-xl md:text-2xl pb-5">Total Supply: {currentSupply} / 7812</p>
                    <p className="underline">
                      Contract{" "}
                      <a href={`https://snowtrace.io/address/${CONTRACT_JSON.address}`} target="_blank">
                        {CONTRACT_JSON.address.substring(0, 10)}...
                      </a>
                    </p>
                    <p className="underline pt-5">
                      <a href={`https://nftrade.com/assets/avalanche/${CONTRACT_JSON.address}`}>View on NFTrade</a>
                    </p>
                  </div>
                  <div className="bg-gray-700 rounded-lg">
                    <Tabs variant={3}>
                      <Tab title="Whitelist">
                        <MintCard
                          title={"Whitelist Mint"}
                          mintPrice={0.8}
                          mintType={MintType.WHITELIST}
                          contract={connectedContract}
                          provider={provider}
                          setShouldUpdate={setShouldUpdate}
                          providerError={error}
                        />
                      </Tab>
                      <Tab title="Public">
                        <MintCard
                          title={"Public Mint"}
                          mintPrice={1}
                          mintType={MintType.PUBLIC}
                          contract={connectedContract}
                          provider={provider}
                          setShouldUpdate={setShouldUpdate}
                          providerError={error}
                        />
                      </Tab>
                      <Tab title="Free">
                        <MintCard
                          title={"Free Mint"}
                          mintPrice={0}
                          mintType={MintType.FREE}
                          contract={connectedContract}
                          provider={provider}
                          setShouldUpdate={setShouldUpdate}
                          providerError={error}
                        />
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              )}
            </div>
          )}
          {error !== undefined ? (
            <div className="p-4 mb-4 rounded-lg bg-red-200 text-red-800" role="alert">
              <span className="font-medium">{error.name} </span> {error.message}
            </div>
          ) : (
            <></>
          )}
          {rpcErrorMessage !== undefined && rpcErrorMessage.length != 0 ? (
            <div className="p-4 mb-4 rounded-lg bg-red-200 text-red-800" role="alert">
              <span className="font-medium">An error was encountered: </span> {rpcErrorMessage}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </PageLayout>
  );
};

function resetState(
  setCurrentSaleStatus: React.Dispatch<React.SetStateAction<string | undefined>>,
  setRpcErrorMessage: React.Dispatch<React.SetStateAction<string>>,
  setFreeMintRemaining: React.Dispatch<React.SetStateAction<number | undefined>>,
  setWhiteListRemaining: React.Dispatch<React.SetStateAction<number | undefined>>,
  setSignerAddress: React.Dispatch<React.SetStateAction<string | undefined>>,
  setCurrentSupply: React.Dispatch<React.SetStateAction<number | undefined>>,
  setConnectedContract: React.Dispatch<React.SetStateAction<AsgardianBulls | undefined>>
) {
  setCurrentSaleStatus(undefined);
  setRpcErrorMessage("");
  setFreeMintRemaining(undefined);
  setWhiteListRemaining(undefined);
  setSignerAddress(undefined);
  setCurrentSupply(undefined);
  setConnectedContract(undefined);
}

export default Mint;
